/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    position: relative;
    font-size: 15px;
    overflow: hidden;

    .wpo-support-section {
        padding: 70px 0;


        .wpo-support-title {
            h2 {
                font-size: 44px;
                font-weight: 700;
                line-height: 53px;
                text-align: left;
                margin-bottom: 0;
                color: $white;

                @media (max-width:1399px) {
                    font-size: 41px;
                    line-height: 50px;
                }

                @media (max-width:1199px) {
                    font-size: 34px;
                    line-height: 40px;
                }

                @media (max-width:991px) {
                    text-align: center;
                }

                @media (max-width:575px) {
                    font-size: 30px;
                }
            }
        }

        .wpo-support-icon {
            position: relative;

            @media (max-width:991px) {
                margin: 30px 0;
            }

            .shape-1 {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }

            .support {
                display: flex;
                justify-content: center;

                .shape {
                    text-align: center;
                    background: $theme-primary-color;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        position: absolute;
                        content: '';
                        left: -7%;
                        top: -6%;
                        transform: translate(-50%, -50%);
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        background: rgb(42 69 112);
                        z-index: -1;
                        animation: pulse 1s ease-in infinite;
                    }

                    i {

                        &::before {
                            color: $white;
                            font-size: 38px;
                            line-height: 80px;

                            @media (max-width:767px) {
                                font-size: 30px;
                            }
                        }
                    }
                }

            }
        }

        .wpo-support-munber {
            text-align: left;
            margin-left: 50px;

            @media (max-width:1399px) {
                margin-left: 0;
            }

            @media (max-width:991px) {
                text-align: center;
            }

            span {
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                color: $text-color-s2;
            }

            P {
                font-size: 32px;
                font-weight: 700;
                line-height: 42px;
                color: $white;
                margin-top: 8px;

                @media (max-width:1199px) {
                    font-size: 25px;
                    line-height: 35px;
                }

                @media (max-width:991px) {
                    margin: 0;
                }
            }
        }

    }

    .footer-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $theme-primary-color-s2;
            z-index: 11;
            opacity: 0.9;
        }
    }

    ul {
        list-style: none;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 70px 0;
        border-top: 1px solid rgb(255, 255, 255, 0.1);

        @media (max-width:1199px) {
            padding: 60px 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 40px;
        }
    }


    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 30px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 700;
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .wpo-service-link-widget {
        padding-left: 70px;

    }

    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1200px) {
            padding-left: 75px;

            &:first-child {
                padding-left: 0;
            }
        }

        @media (max-width: 1199px) {
            padding-left: 20px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: #BFBAC8;
                    font-size: 15px;
                    font-weight: 400;

                    i {
                        padding-right: 18px;

                        &::before {
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }

    .contact-widget {
        position: relative;
        padding-left: 94px;

        @media (max-width:1199px) {
            padding-left: 47px;
        }

        @media (max-width:991px) {
            padding-left: 0;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 52%;
            transform: translateY(-50%);
            width: 1px;
            height: 423px;
            background: rgb(255, 255, 255, 0.1);
            content: '';

            @media (max-width:1199px) {
                top: 55%;
            }

            @media (max-width:991px) {
                display: none;
            }
        }

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            top: 52%;
            transform: translateY(-50%);
            width: 1px;
            height: 423px;
            background: rgb(255, 255, 255, 0.1);
            content: '';

            @media (max-width:1199px) {
                top: 55%;
            }

            @media (max-width:991px) {
                display: none;
            }
        }

        .shape-1 {
            position: absolute;
            left: -1px;
            top: 50%;
            width: 3px;
            height: 100px;
            background: $theme-primary-color;

            @media (max-width:991px) {
                display: none;
            }
        }

        .shape-2 {
            position: absolute;
            right: -1px;
            top: 50%;
            width: 3px;
            height: 100px;
            background: $theme-primary-color;

            @media (max-width:991px) {
                display: none;
            }
        }

        ul {
            li {
                position: relative;
                color: #BFBAC8;
                font-size: 15px;
                font-weight: 400;
            }

            li+li {
                padding-top: 5px;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            text-align: left;
            color: $text-color-s2;
            margin-top: 20px;
        }

        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: #163668;
                height: 55px;
                width: 244px;
                color: #BFBAC8;
                padding: 6px 15px;
                border: 1px solid transparent;
                box-shadow: none;

                @media (max-width:767px) {
                    width: 100%;
                }
            }

            .submit {
                position: absolute;
                right: 31%;
                top: 50%;
                @include translatingY();

                @media (max-width:1199px) {
                    right: 10%;
                }

                @media (max-width:991px) {
                    right: 12%;

                }

                @media (max-width:767px) {
                    right: -5px;
                }

                button {
                    background: $theme-primary-color;
                    border: 1px solid transparent;
                    outline: 0;
                    font-size: 20px;
                    color: $white;
                    width: 56px;
                    height: 52px;
                    line-height: 45px;
                    border-radius: 0;
                    clip-path: polygon(100% 0, 100% 75%, 88% 100%, 0 100%, 0 0);

                    i {
                        &::before {
                            font-size: 25px;
                            line-height: 50px;
                        }
                    }
                }
            }
        }
    }

    /*** blog-widget  ***/

    .blog-widget {
        margin-left: 70px;

        @media (max-width:1199px) {
            margin-left: 25px;
        }

        @media (max-width:991px) {
            margin-left: 0px;
        }

        @media(max-width:1200px) {
            padding: 0;
        }

        ul {
            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                .image {
                    width: 30%;

                    @media (max-width:767px) {
                        width: 20%;
                    }

                    @media (max-width:575px) {
                        width: 15%;
                    }
                    @media (max-width:399px) {
                        width: 20%;
                    }

                    img {
                        @media (max-width:399px) {
                            width: 100%;
                        }
                    }
                }

                .text {
                    width: 80%;

                    @media (max-width:1199px) {}

                    @media (max-width:767px) {
                        padding-right: 120px;
                    }

                    @media (max-width:575px) {
                        padding-left: 15px;
                        padding-right: 110px;
                        width: 85%;
                    }
                    @media (max-width:399px) {
                        padding-left: 15px;
                        padding-right: 0px;
                        width: 80%;
                    }

                    p {
                        font-size: 15px;
                        font-weight: 700;
                        margin-bottom: 5px;

                        a {
                            color: $white;

                            &:hover {
                                color: $theme-primary-color;
                            }
                        }
                    }

                    span {
                        font-size: 14px;
                        color: $theme-primary-color;
                        font-weight: 700;
                    }
                }

            }
        }
    }

    .social-widget {
        ul {
            li {
                a {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 10px;

                    &:hover {
                        color: $theme-primary-color;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }


    .wpo-lower-footer {
        position: relative;
        background: #163668;

        .row {
            padding: 20px 0;
            position: relative;
        }

        .copyright {
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            text-align: left;

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
                text-align: center;
            }
        }

        .social-widget {
            text-align: right;

            @media (max-width: 991px) {
                text-align: center;
                margin-top: 20px;
            }

            ul {
                li {
                    display: inline-block;

                    a {

                        color: $white;
                        background: $theme-primary-color;
                        margin-bottom: 0;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;

                        &:hover {
                            color: $theme-primary-color-s2;
                        }

                        i {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 0;

                            &::before {
                                font-size: 15px;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }


    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}