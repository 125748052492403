.wpo-section-title {
	margin-bottom: 60px;
	text-align: center;
	margin-top: 6px;
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: uppercase;
		color: $text-color-s2;
		font-family: $heading-font;
		font-weight: 700;
		font-size: 13px;
		line-height: 35px;
		letter-spacing: 0.2em;
		border: 2px solid $border-color;
		padding: 13px 24px;
		border-radius: 50px;
	}

	h2 {
		margin: 0;
		margin-top: 20px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		font-size: 53px;
		font-weight: 700;
		line-height: 69px;
		color: $text-color;
		

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p{
		font-size: 18px;
	}

}