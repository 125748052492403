@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?8dd43a47991effac14736a627d429cb0") format("truetype"),
url("../fonts/flaticon.woff?8dd43a47991effac14736a627d429cb0") format("woff"),
url("../fonts/flaticon.woff2?8dd43a47991effac14736a627d429cb0") format("woff2"),
url("../fonts/flaticon.eot?8dd43a47991effac14736a627d429cb0#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?8dd43a47991effac14736a627d429cb0#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-email:before {
    content: "\f101";
}
.flaticon-phone-call:before {
    content: "\f102";
}
.flaticon-maps-and-flags:before {
    content: "\f103";
}
.flaticon-instagram:before {
    content: "\f104";
}
.flaticon-gallery:before {
    content: "\f105";
}
.flaticon-serving-dish:before {
    content: "\f106";
}
.flaticon-edit:before {
    content: "\f107";
}
.flaticon-wedding:before {
    content: "\f108";
}
.flaticon-cake:before {
    content: "\f109";
}
.flaticon-wedding-rings:before {
    content: "\f10a";
}
.flaticon-play:before {
    content: "\f10b";
}
.flaticon-quotation:before {
    content: "\f10c";
}
.flaticon-heart:before {
    content: "\f10d";
}
.flaticon-pinterest:before {
    content: "\f10e";
}
.flaticon-facebook-app-symbol:before {
    content: "\f10f";
}
.flaticon-twitter:before {
    content: "\f110";
}
.flaticon-instagram-1:before {
    content: "\f111";
}
.flaticon-linkedin:before {
    content: "\f112";
}
.flaticon-youtube:before {
    content: "\f113";
}
.flaticon-search:before {
    content: "\f114";
}
.flaticon-shopping-cart:before {
    content: "\f115";
}
.flaticon-left-arrow:before {
    content: "\f116";
}
.flaticon-user:before {
    content: "\f117";
}
.flaticon-comment-white-oval-bubble:before {
    content: "\f118";
}
.flaticon-calendar:before {
    content: "\f119";
}
.flaticon-right-arrow:before {
    content: "\f11a";
}
.flaticon-play-1:before {
    content: "\f11b";
}
.flaticon-left-quote:before {
    content: "\f11c";
}
.flaticon-left-arrow-1:before {
    content: "\f11d";
}
.flaticon-next:before {
    content: "\f11e";
}
.flaticon-verified:before {
    content: "\f11f";
}
.flaticon-shield:before {
    content: "\f120";
}
.flaticon-world-wide-web:before {
    content: "\f121";
}
.flaticon-phone-call-1:before {
    content: "\f122";
}
.flaticon-email-1:before {
    content: "\f123";
}
.flaticon-house:before {
    content: "\f124";
}
.flaticon-completed:before {
    content: "\f125";
}
.flaticon-quotation-1:before {
    content: "\f126";
}
.flaticon-location:before {
    content: "\f127";
}
.flaticon-coding:before {
    content: "\f128";
}
.flaticon-technical-support:before {
    content: "\f129";
}
.flaticon-right-quote:before {
    content: "\f12a";
}
.flaticon-send:before {
    content: "\f12b";
}
.flaticon-engineering:before {
    content: "\f12c";
}
.flaticon-automation:before {
    content: "\f12d";
}
.flaticon-construction-and-tools:before {
    content: "\f12e";
}
.flaticon-arrow:before {
    content: "\f12f";
}
.flaticon-right-arrow-1:before {
    content: "\f130";
}
