/*======================================
5. Home-style-2
 =======================================*/

/* 5.1 wpo-project-section-s2 */
.wpo-project-section-s2 {
  .wpo-section-title {
    text-align: center;
  }

  .wpo-project-content {
    margin-top: 30px;
  }
}

/* 5.2 wpo-fun-fact-section */
.wpo-fun-fact-section,
.wpo-fun-fact-section-s2 {
  text-align: center;
  position: relative;
  z-index: 1;
  background: url(../../images/slider/home02_here.avif);
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    background: $theme-primary-color-s2;
    opacity: 0.9;
  }

  @media (max-width: 991px) {
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }

  .wpo-fun-fact-grids .grid {
    width: 25%;
    float: left;

    @include media-query(767px) {
      width: 50%;
      margin-bottom: 50px;
    }
  }

  .grid {
    position: relative;

    h3 {
      font-size: 60px;
      font-size: calc-rem-value(60);
      font-weight: 700;
      color: $text-color-s2;
      margin: 0 0 0.2em;
      font-family: $heading-font;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-query(1200px) {
        font-size: 50px;
        ont-size: calc-rem-value(50);
      }

      @include media-query(991px) {
        font-size: 40px;
        font-size: calc-rem-value(40);
      }

      @include media-query(767px) {
        font-size: 35px;
        font-size: calc-rem-value(35);
      }

      span {
        font-family: $heading-font;
      }
    }
  }

  .grid h3 + p {
    font-size: 20px;
    font-size: calc-rem-value(20);
    margin: 0;
    color: $white;

    @include media-query(1200px) {
      font-size: 20px;
      font-size: calc-rem-value(20);
    }

    @include media-query(991px) {
      font-size: 16px;
      font-size: calc-rem-value(16);
    }
  }
}

/* 5.3 wpo-pricing-section */
.wpo-pricing-section {
  .wpo-pricing-wrap {
    .wpo-pricing-item {
      box-shadow: 0px 2px 16.8px 3.2px rgba(0, 38, 66, 0.08);

      @media (max-width: 991px) {
        margin-bottom: 30px;
      }

      .wpo-pricing-top {
        padding: 30px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;

        .pricing-thumb {
          display: inline-block;
          padding: 3px 30px 5px;
          background: $theme-primary-color;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          span {
            text-transform: uppercase;
            color: $white;
          }
        }

        .wpo-pricing-text {
          margin-top: 30px;

          h2 {
            color: $dark-gray;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            font-weight: 700;

            span {
              font-weight: 500;
              color: $text-color;
              font-size: 16px;
              text-transform: uppercase;
            }
          }

          p {
            padding-bottom: 30px;
            max-width: 282px;
            margin: 0 auto;
            border-bottom: 1px solid $theme-primary-color;
          }
        }
      }

      .wpo-pricing-bottom {
        padding: 40px;
        padding-top: 35px;
        text-align: center;

        .wpo-pricing-bottom-text {
          ul {
            list-style: none;

            li {
              color: $text-light-color;
              padding-bottom: 15px;
            }
          }

          a {
            font-size: 16px;
            color: $theme-primary-color;
            font-weight: 500;
            display: inline-block;
            margin-top: 20px;
            position: relative;
            color: $white;

            &:hover {
              background: $theme-primary-color-s2;
              color: $white;

              &:before {
                background-color: $theme-primary-color;
              }
            }
          }
        }
      }
    }

    .col {
      &:last-child {
        .wpo-pricing-item {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* 5.4 wpo-about-section-s2 */
.wpo-about-section-s2 {
  .about-img {
    margin-right: 75px;
    position: relative;
    overflow: hidden;

    @media (max-width: 1399px) {
      margin: 0;
    }

    img {
      width: 100%;
    }

    .images-text {
      position: absolute;
      left: -187px;
      top: 280px;
      transform: rotate(90deg);
      padding: 50px 95px;
      background: $section-bg-color;

      @media (max-width: 1399px) {
        top: 262px;
      }

      @media (max-width: 1399px) {
        top: 212px;
        left: -177px;
        padding: 35px 60px;
      }

      @media (max-width: 991px) {
        left: -187px;
        top: 431px;
        padding: 50px 95px;
      }

      @media (max-width: 767px) {
        left: -177px;
        top: 287px;
        padding: 27px 60px;
      }

      @media (max-width: 575px) {
        top: 43%;
        padding: 20px 50px;
        left: -19%;
      }

      @media (max-width: 424px) {
        top: 42%;
        padding: 15px 20px;
        left: -25%;
      }

      h3 {
        margin: 0;
        font-size: 35px;
        font-weight: 700;
        text-align: center;
        background: $theme-primary-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 575px) {
          font-size: 24px;
        }
      }
    }
  }

  .about-content {
    margin-left: 35px;

    @media (max-width: 1399px) {
      margin-left: 50px;
    }

    @media (max-width: 1199px) {
      margin-left: 0;
      margin-top: -35px;
    }

    @media (max-width: 991px) {
      margin-top: 50px;
      margin-bottom: 17px;
    }

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    .wpo-section-title {
      text-align: left;
      position: unset;
      margin-bottom: 0;

      span {
        text-transform: capitalize;
      }

      h2 {
        position: unset;
        line-height: 60px;
        padding-bottom: 18px;

        @media (max-width: 1199px) {
          margin-top: 5px;
        }

        @media (max-width: 767px) {
          line-height: 40px;
        }
      }
    }

    .wpo-about-text {
      @media (max-width: 991px) {
        margin-bottom: 0;
      }

      p {
        font-size: 18px;
        margin-bottom: 35px;
        color: $text-color;

        @media (max-width: 1199px) {
          font-size: 15px;
          margin-bottom: 20px;
        }

        @media (max-width: 991px) {
          font-size: 17px;
          margin-bottom: 20px;
        }
      }

      ul {
        list-style: none;
        margin-bottom: 50px;

        @media (max-width: 1199px) {
          margin-bottom: 60px;
        }

        @media (max-width: 991px) {
          margin-bottom: 50px;
        }

        li {
          margin-bottom: 12px;
          padding-left: 50px;
          position: relative;
          color: $text-color;
          font-size: 18px;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 16px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            font-weight: 400;
            font-size: 14px;
            transform: translateY(-50%);
            content: "\e64c";
            font-family: "themify";
            color: $theme-primary-color;
            background: rgb(68 179 249 / 25%);
            border-radius: 50px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
